@import '../variables';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Noto Sans', sans-serif;
    background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $primary-color;
}

small {
    color: gray;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    width: 100%;
}

.error-message {
    background-color: lighten($color: $error-color, $amount: 25);
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
}

.success-message {
    background-color: lighten($color: $success-color, $amount: 25);
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
}

.logout-button {
    cursor: pointer;
    padding: 2px;
}

.full-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #F5F5F5;
}

button,
.button {
    padding: .65rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    transition: all 0.3s;
    justify-content: center;
    gap: 0.5rem;
}

.small-button {
    padding: 0.5rem 0.8rem;
}

table {
    width: 100%;
    border-collapse: collapse;
    position: relative;

    th,
    td {
        padding: 0.7rem;
        // border-bottom: 1px solid #e1e4e8;
        text-align: start;
        font-size: 12px;
    }

    tr {
        &:nth-child(even) {
            background-color: #F2F8F7;
        }

        &:last-child {
            text-align: end;
        }
    }

    thead {
        background-color: #DFF1F0;
        position: sticky;
        // top: 176px;
        z-index: 1;
    }

    .table-items {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

.primary-button {
    background-color: $primary-color;
    color: white;

    &:hover {
        background-color: darken($primary-color, 5%);
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.outline-button {
    border: 1px solid $primary-color;
}

.form-button {
    padding: 0.8rem 2rem;
}

.full-button {
    width: 100%;
}

form,
.form-container {
    display: grid;
    height: fit-content;
    gap: 1rem;

    .form-group {
        display: grid;
        gap: 0.5rem;
        height: fit-content;
    }
}

.form-choices {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;

    .choice {
        // flex: 1 1;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.3rem 0.5rem;
        cursor: pointer;
        transition: background-color 0.3s;
        border-radius: 0.4rem;
    }
}

.loading-article {
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-color;
}

.half {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    .form-group {
        flex: 1 1 200px;
    }
}

.buttons {
    display: flex;
    align-items: center;
    gap: 1rem;

    button,
    .button {
        flex: 1;
    }
}

.select {
    position: relative;

    .options {
        position: absolute;
        top: 3rem;
        left: 0;
        right: 0;
        background-color: white;
        padding: 1rem;
        border-radius: 0.4rem;
        border: 1px solid #ccc;
        max-height: 300px;
        overflow-y: auto;
        display: grid;

        .option {
            border-bottom: 1px solid #ccc;
            padding: 0.5rem 0;

            &:hover {
                background-color: #f2f2f2;
            }
        }

        &>:last-child {
            border-bottom: none;
        }
    }
}

input,
textarea,
select {
    flex: 1;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    border: 1px solid $border-color;
    color: $text-color;

    &::placeholder {
        color: $border-color;
    }

    &:focus {
        border-color: $primary-color;
    }
}

input[type="file"] {
    padding: 0;
    border: none;
}

.login-form {
    width: 100%;
    max-width: 600px;
    display: grid;
    height: fit-content;
    gap: 1rem;

    border: 1px solid #ccc;
    padding: 2rem;
    border-radius: 0.3rem;
}

.loading-icon {
    animation: loading-animation 1s infinite linear;
}

.link-field {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.pop-up {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);

    .popup-content {
        background-color: white;
        padding: 2rem;
        border-radius: 0.5rem;
        width: 100%;
        max-width: 600px;
        position: relative;
    }

    .close-icon {
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
    }
}


.author-card {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background-color: lighten($color: $primary-color, $amount: 65);
    border: 1px solid $primary-color;
    padding: 0.5rem;
    border-radius: 0.5rem;

    .name-icon {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}

.name-initials {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    color: white;
    border-radius: 0.3rem;
    font-size: 14px;


}


.upload-image-container {
    display: grid;
    height: fit-content;
    gap: 0.5rem;
}

.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    height: 70vh;
    width: 100%;
}

@keyframes loading-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}