@import '../variables';

.articles-page {
    display: grid;
    height: fit-content;
    gap: 1rem;

    .search-filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        border: 1px solid lighten($color: $border-color, $amount: 20);
        padding: 0.5rem;
        border-radius: 0.5rem;
        position: sticky;
        top: 96px;
        z-index: 2;
        background-color: whitesmoke;

        .search {
            display: flex;
            align-items: center;
            gap: 1rem;
            flex: 1;

            input {
                flex: 1;
                max-width: 500px;
            }
        }
    }
}

.pagination {
    position: fixed;
    bottom: 2rem;
    right: 1rem;

    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: white;
    z-index: 3;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.new-article-page {
    display: grid;
    height: fit-content;
    gap: 1rem;

    .new-article {
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        .other-content-cards {
            flex: 1;
            max-width: 332px;
            background-color: white;
            padding: 1rem;
            border-radius: 0.5rem;
            display: grid;
            height: fit-content;
            gap: 1rem;


        }

        .article-content {
            flex: 1;
            background-color: white;
            padding: 1rem;
            border-radius: 0.5rem;
        }


    }

    .selected-category {
        background-color: lighten($color: $primary-color, $amount: 60);
        border: 1px solid $primary-color;
    }
}

.card {
    padding: 0.5rem;
    border: 1px solid lighten($color: $border-color, $amount: 20);
    border-radius: 0.5rem;
    display: grid;
    height: fit-content;
    gap: 0.5rem;


}

.featured-image {
    aspect-ratio: 16/9;
    object-fit: cover;
}