.page-container .content-container {
  display: flex;
}
.page-container .content-container .side-bar {
  background-color: #00AAA1;
  width: 240px;
  color: white;
  position: sticky;
  top: 0;
  bottom: 0;
  height: 100vh;
  overflow: auto;
  transition: width 0.3s ease-in-out;
}
.page-container .content-container .page-content {
  flex: 1;
  background-color: #f2f2f2;
}
.page-container .content-container .page-content .content {
  padding: 0.8rem;
}
.page-container .content-container .collapsed {
  width: 70px;
}
.page-container .content-container .collapsed .header .branding .title {
  display: none;
}
.page-container .content-container .collapsed .feature .feature-title {
  justify-content: center;
}
.page-container .content-container .collapsed .feature span,
.page-container .content-container .collapsed .feature .icon {
  display: none;
}
.page-container .content-container .collapsed .feature .element {
  justify-content: center;
  display: flex;
  align-items: center;
}

.header {
  background-color: #009189;
  height: 80px;
  padding: 1rem;
  display: flex;
  align-items: center;
}
.header .branding {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.header .branding .logo {
  max-width: 42px;
}
.header .branding .title {
  color: white;
}

.feature .feature-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #00a097;
}
.feature .feature-title .name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.feature .feature-elements {
  display: grid;
}
.feature .feature-elements .element {
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.feature .feature-elements .element:hover {
  background-color: #00a097;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid #eee;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}
header .profile-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
}
header .profile-container h1,
header .profile-container h2,
header .profile-container h3,
header .profile-container h4,
header .profile-container h5,
header .profile-container h6 {
  color: #363636;
}
header .profile-image {
  border: 1px solid black;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  padding: 5px;
}
header .profile-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
header .profile-details {
  display: grid;
}/*# sourceMappingURL=pageContainer.css.map */