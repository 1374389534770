@import "../variables";

.page-container {
    .content-container {
        display: flex;

        .side-bar {
            background-color: $primary-color;
            width: 240px;
            color: white;
            position: sticky;
            top: 0;
            bottom: 0;
            height: 100vh;
            overflow: auto;
            transition: width 0.3s ease-in-out;
        }

        .page-content {
            flex: 1;
            background-color: #f2f2f2;

            .content {
                padding: 0.8rem;
            }

        }

        .collapsed {
            width: 70px;

            .header {
                .branding {
                    .title {
                        display: none;
                    }
                }
            }

            .feature {

                .feature-title {

                    justify-content: center;

                }

                span,
                .icon {
                    display: none;
                }

                .element {
                    justify-content: center;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.header {
    background-color: darken($color: $primary-color, $amount: 5);
    height: 80px;
    padding: 1rem;
    display: flex;
    align-items: center;

    .branding {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .logo {
            max-width: 42px;
        }

        .title {
            color: white;
        }
    }
}

.feature {
    .feature-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        background-color: darken($color: $primary-color, $amount: 2);

        .name {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }

    .feature-elements {
        display: grid;

        .element {
            padding: 0.8rem 1rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &:hover {
                background-color: darken($color: $primary-color, $amount: 2);
            }
        }
    }
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-bottom: 1px solid #eee;
    padding: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;

    .profile-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        // background-color: whitesmoke;
        padding: 0.5rem 1rem;
        border-radius: 0.4rem;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $text-color
        }
    }

    .profile-image {
        border: 1px solid black;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        padding: 5px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

    }

    .profile-details {
        display: grid;
    }
}