@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Noto Sans", sans-serif;
  background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #00AAA1;
}

small {
  color: gray;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
}

.error-message {
  background-color: #f6d6d5;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
}

.success-message {
  background-color: #d1ebd1;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
}

.logout-button {
  cursor: pointer;
  padding: 2px;
}

.full-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #F5F5F5;
}

button,
.button {
  padding: 0.65rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  transition: all 0.3s;
  justify-content: center;
  gap: 0.5rem;
}

.small-button {
  padding: 0.5rem 0.8rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}
table th,
table td {
  padding: 0.7rem;
  text-align: start;
  font-size: 12px;
}
table tr:nth-child(even) {
  background-color: #F2F8F7;
}
table tr:last-child {
  text-align: end;
}
table thead {
  background-color: #DFF1F0;
  position: sticky;
  z-index: 1;
}
table .table-items {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.primary-button {
  background-color: #00AAA1;
  color: white;
}
.primary-button:hover {
  background-color: #009189;
}
.primary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.outline-button {
  border: 1px solid #00AAA1;
}

.form-button {
  padding: 0.8rem 2rem;
}

.full-button {
  width: 100%;
}

form,
.form-container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
form .form-group,
.form-container .form-group {
  display: grid;
  gap: 0.5rem;
  height: -moz-fit-content;
  height: fit-content;
}

.form-choices {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.form-choices .choice {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 0.4rem;
}

.loading-article {
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00AAA1;
}

.half {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.half .form-group {
  flex: 1 1 200px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.buttons button,
.buttons .button {
  flex: 1;
}

.select {
  position: relative;
}
.select .options {
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  background-color: white;
  padding: 1rem;
  border-radius: 0.4rem;
  border: 1px solid #ccc;
  max-height: 300px;
  overflow-y: auto;
  display: grid;
}
.select .options .option {
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 0;
}
.select .options .option:hover {
  background-color: #f2f2f2;
}
.select .options > :last-child {
  border-bottom: none;
}

input,
textarea,
select {
  flex: 1;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  border: 1px solid #999999;
  color: #363636;
}
input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
  color: #999999;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #999999;
}
input:focus,
textarea:focus,
select:focus {
  border-color: #00AAA1;
}

input[type=file] {
  padding: 0;
  border: none;
}

.login-form {
  width: 100%;
  max-width: 600px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  border: 1px solid #ccc;
  padding: 2rem;
  border-radius: 0.3rem;
}

.loading-icon {
  animation: loading-animation 1s infinite linear;
}

.link-field {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pop-up {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.pop-up .popup-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 600px;
  position: relative;
}
.pop-up .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.author-card {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  background-color: #f7ffff;
  border: 1px solid #00AAA1;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.author-card .name-icon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.name-initials {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00AAA1;
  color: white;
  border-radius: 0.3rem;
  font-size: 14px;
}

.upload-image-container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}

.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  height: 70vh;
  width: 100%;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=main.css.map */