.articles-page {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.articles-page .search-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border: 1px solid #cccccc;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: sticky;
  top: 96px;
  z-index: 2;
  background-color: whitesmoke;
}
.articles-page .search-filters .search {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}
.articles-page .search-filters .search input {
  flex: 1;
  max-width: 500px;
}

.pagination {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: white;
  z-index: 3;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.new-article-page {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.new-article-page .new-article {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.new-article-page .new-article .other-content-cards {
  flex: 1;
  max-width: 332px;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.new-article-page .new-article .article-content {
  flex: 1;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}
.new-article-page .selected-category {
  background-color: #ddfffd;
  border: 1px solid #00AAA1;
}

.card {
  padding: 0.5rem;
  border: 1px solid #cccccc;
  border-radius: 0.5rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}

.featured-image {
  aspect-ratio: 16/9;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=articles.css.map */